import * as React from "react";
import { Modal } from "../../common/Modal/Modal";
import AlertDetailsList from "./AlertDetailsList";
import { useGetCheckAlertsById } from "../../../queries/checkAlerts";
import { CheckAlertsModel } from "../AlertsList/entities.typings";

export interface Props {
  show: boolean;
  onClose: () => void;
  checkSerieId: number;
}

export const AlertDetailsModal: React.FC<Props> = ({ show, onClose, checkSerieId }: Props) => {
  const NO_ALERTS_MSG = "No details available for this alert";
  const [checkAlertsList, setCheckAlertsList] = React.useState<CheckAlertsModel[]>([]);
  const [showAlertsList, setShowAlertsList] = React.useState<boolean>(false);
  const { data: checkAlerts } = useGetCheckAlertsById(checkSerieId, !!checkSerieId);

  const handleCloseModal = () => {
    setShowAlertsList(false);
    onClose();
  };

  React.useEffect(() => {
    if (checkAlerts) {
      const headers = checkAlerts.headers;
      const lines = checkAlerts.lines;

      if (headers && lines && headers.length !== 0 && lines.length !== 0) {
        const transformedData: CheckAlertsModel[] = lines.map((line) => {
          const item: { [key: string]: string } = {};
          headers.forEach((header, i) => {
            item[header] = line.lines[i];
          });
          return item;
        });
        setCheckAlertsList(transformedData);
        setShowAlertsList(true);
      } else {
        setShowAlertsList(false);
      }
    }
  }, [checkAlerts, show]);

  return (
    <>
      {checkAlerts && (
        <Modal
          size="xl"
          title="Alert details"
          cancelLabel="Close"
          show={show}
          backdropClose={true}
          onClose={handleCloseModal}
          onCancel={handleCloseModal}
        >
          <div>{showAlertsList && <AlertDetailsList headers={checkAlerts.headers} list={checkAlertsList} />}</div>
          <div>{!showAlertsList && <div style={{ fontSize: "large" }}>{NO_ALERTS_MSG}</div>}</div>
        </Modal>
      )}
    </>
  );
};
