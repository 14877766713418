import { some } from "lodash";
import { SearchFiltersState } from "./SearchFilters.typings";

/**
 * checks that the filter search state is valid
 * @param searchFiltersState
 * @param searchMode
 */
export const isValidFormState = (searchFiltersState: SearchFiltersState): boolean => {
  const datePickersFilters = [searchFiltersState.startDate, searchFiltersState.endDate];
  return !some(datePickersFilters, { isValid: false });
};
