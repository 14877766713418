import { map } from "lodash";
import { CheckSeriesFilterDto } from "./AlertsList/entities.typings";
import { SearchFiltersState } from "./SearchFilters/SearchFilters.typings";

export const stateToDto = (searchFiltersState: SearchFiltersState | null): CheckSeriesFilterDto =>
  ({
    From: searchFiltersState?.startDate.date,
    To: searchFiltersState?.endDate.date,
    CheckSeriesStatus: map(searchFiltersState?.alertStatusIds, (x) => x.id),
    CheckTypeId: map(searchFiltersState?.alertTypeIds, (x) => x.id),
  } as CheckSeriesFilterDto);
