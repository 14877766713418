import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";
import * as React from "react";
import { agGridLicense } from "../../../utils/ag-grid/ag-grid-license";
import { CheckAlertsModel } from "../AlertsList/entities.typings";

LicenseManager.setLicenseKey(agGridLicense);

export interface AlertDetailsListProps {
  headers: string[];
  list: CheckAlertsModel[];
}
const AlertDetailsList: React.FC<AlertDetailsListProps> = ({ headers, list }) => {
  //const [gridApi, setGridApi] = React.useState<GridApi>();

  const onGridReady = (params: any) => {
    //setGridApi(params.api);
    params.api.sizeColumnsToFit();

    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });
  };
  return (
    <div className="ag-theme-alpine">
      <AgGridReact
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
        domLayout="autoHeight"
        rowData={list}
        onGridReady={onGridReady}
      >
        {headers.map((header) => {
          return <AgGridColumn field={header} headerName={header} tooltipField={header}/>;
        })}
      </AgGridReact>
    </div>
  );
};

export default AlertDetailsList;
