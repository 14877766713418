import { UseMutationResult, useMutation, useQuery } from "react-query";
import { CheckSeriesApi } from "../api/checkSeries.api";
import {
  CheckSerieEditDto,
  CheckSeriesFilterDto,
  CheckSeriesItemDto,
  CheckSeriesResponseDto,
  CheckSeriesStatusesDto,
} from "../components/Alerts/AlertsList/entities.typings";

export const useGetAlertsByFilters = (checkSeriesFilterDto: CheckSeriesFilterDto, enabled: boolean) =>
  useQuery<CheckSeriesResponseDto>(
    ["checkSeries", checkSeriesFilterDto],
    () =>
      CheckSeriesApi.getAlerts({
        From: checkSeriesFilterDto.From,
        To: checkSeriesFilterDto.To,
        CheckSeriesStatus: checkSeriesFilterDto.CheckSeriesStatus,
        CheckTypeId: checkSeriesFilterDto.CheckTypeId,
      } as CheckSeriesFilterDto).then((res) => res),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
    }
  );

export const useGetCheckSeriesStatuses = (enabled: boolean) =>
  useQuery<CheckSeriesStatusesDto>(["statuses"], () => CheckSeriesApi.getCheckSeriesStatuses().then((res) => res), {
    enabled: enabled,
    refetchOnWindowFocus: false,
  });

export const useGetCheckSerieById = (id: number, enabled: boolean) =>
  useQuery<CheckSeriesItemDto>(["checkSerie", { id }], () => CheckSeriesApi.getCheckSerieById(id).then((res) => res), {
    enabled: enabled,
    refetchOnWindowFocus: false,
  });

export const useEditCheckSerie = (): UseMutationResult<
  CheckSeriesItemDto,
  unknown,
  { id: number; checkSerieDto: CheckSerieEditDto }
> => {
  return useMutation(({ id, checkSerieDto }) => CheckSeriesApi.editCheckSerie(id, checkSerieDto).then((res) => res));
};
