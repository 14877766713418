import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { GridApi, ITooltipParams, LicenseManager } from "ag-grid-enterprise";
import * as React from "react";
import { CheckSeriesItemDto } from "./entities.typings";
import { agGridLicense } from "../../../utils/ag-grid/ag-grid-license";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Badge, Button } from "@sgbs-ui/core";
import { formatDate, formatStatus } from "../../../common/Helpers/helpers";
import { SearchInput } from "../../common/SearchInput/SearchInput";
LicenseManager.setLicenseKey(agGridLicense);

export interface AlertsListProps {
  alerts: CheckSeriesItemDto[];
  onEditAlert: (e: React.MouseEvent<HTMLButtonElement>, id: number) => void;
  onOpenDetailsAlert: (e: React.MouseEvent<HTMLButtonElement>, id: number) => void;
}

const AlertsList: React.FC<AlertsListProps> = ({ alerts, onEditAlert, onOpenDetailsAlert }: AlertsListProps) => {
  const [gridApi, setGridApi] = React.useState<GridApi>();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onFilterTextBoxChanged = (event: any) => {
    const value = event?.target.value;
    gridApi?.setQuickFilter(value);
  };

  return (
    <div>
      <div className="form-group w-25">
        <SearchInput
          id="alerts-filter"
          onChange={onFilterTextBoxChanged}
          className="form-control md"
          placeHoldertext="Quick search"
          iconName="search"
        />
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          domLayout="autoHeight"
          rowData={alerts}
          pagination={true}
          paginationPageSize={15}
          onGridReady={onGridReady}
          suppressHorizontalScroll={true}
          enableCellExpressions={true}
          tooltipShowDelay={1000}
        >
          <AgGridColumn field="checkSeriesId" headerName="Alert Id" suppressSizeToFit={true} width={100} />
          <AgGridColumn
            field="receptionDate"
            headerName="Date"
            flex={1}
            valueFormatter={(date: any) => formatDate(date.value)}
          />
          <AgGridColumn field="checkType" headerName="Alert type" flex={1} />
          <AgGridColumn
            field="checkSeriesStatus"
            headerName="Status"
            flex={1}
            cellRendererFramework={(params: any) =>
              params.value === "successful" ? (
                <Badge color="success" text={formatStatus(params.value)} />
              ) : (
                <Badge color="danger" text={formatStatus(params.value)} />
              )
            }
          />
          <AgGridColumn
            field="alertSummary"
            headerName="Summary"
            flex={2.5}
            tooltipValueGetter={ (params: ITooltipParams) => params.value }
          />
          <AgGridColumn
            field="alertOpenDetails"
            headerName="Details"
            flex={1}
            cellRendererFramework={(node: { data: { checkSeriesId: number } }) => (
              <Button
                className="btn btn-sm btn-primary btn-icon-text"
                onClick={(e) => onOpenDetailsAlert(e, node.data.checkSeriesId)}
                icon="list"
              >
                Open details
              </Button>
            )}
          />
          <AgGridColumn
            field="alertEdit"
            headerName="Edit"
            flex={1}
            cellRendererFramework={(node: { data: { checkSeriesId: number } }) => (
              <Button
                className="btn btn-sm btn-primary btn-icon-text"
                onClick={(e) => onEditAlert(e, node.data.checkSeriesId)}
                icon="edit"
              >
                Edit
              </Button>
            )}
          />
          <AgGridColumn field="comment" headerName="Comment" flex={1} />
          <AgGridColumn field="lastModifiedOn" headerName="Last Modified On" flex={1} />
          <AgGridColumn field="lastModifiedBy" headerName="Last Modified By" flex={1} />
        </AgGridReact>
      </div>
    </div>
  );
};

export default AlertsList;
