import {
  CheckSerieEditDto,
  CheckSeriesFilterDto,
  CheckSeriesItemDto,
  CheckSeriesResponseDto,
  CheckSeriesStatusesDto,
} from "../components/Alerts/AlertsList/entities.typings";
import { createRepository, getAuthenticationHeader } from "./api";
import { ToastMessage } from "./api.typings";
import { getEditCheckSerieQueryParams } from "./checkSeries.api.helpers";
import { buildFilters } from "./checkSeries.api.helpers";
import { ICheckSeriesApi } from "./checkSeries.api.typings";

export const CheckSeriesApi: ICheckSeriesApi = {
  getAlerts: (filters: CheckSeriesFilterDto): Promise<CheckSeriesResponseDto> =>
    createRepository().get<CheckSeriesResponseDto>(
      `${process.env.REACT_APP_DATAQUALITY_API}/api/v1/check-series?${buildFilters(filters)}`,
      getAuthenticationHeader()
    ),
  getCheckSeriesStatuses: (): Promise<CheckSeriesStatusesDto> =>
    createRepository().get<CheckSeriesStatusesDto>(
      `${process.env.REACT_APP_DATAQUALITY_API}/api/v1/check-series/statuses`,
      getAuthenticationHeader()
    ),

  editCheckSerie: (id: number, editcheckSerieDto: CheckSerieEditDto): Promise<CheckSeriesItemDto> => {
    const queryParams = getEditCheckSerieQueryParams(editcheckSerieDto);
    return createRepository().patch<FormData, CheckSeriesItemDto>(
      `${process.env.REACT_APP_DATAQUALITY_API}/api/v1/check-series/${id}?${queryParams}`,
      getAuthenticationHeader(),
      new FormData(),
      {
        successMessage: "Check serie updated successfully",
        friendlyErrorMessage: "Error during update",
        badRequestErrorMessage: "Bad request",
        notAuthorizedErrorMessage: "Not authorized",
        forbiddenErrorMessage: "Forbidden access"
      } as ToastMessage
    );
  },

  getCheckSerieById: (id: number): Promise<CheckSeriesItemDto> =>
    createRepository().get<CheckSeriesItemDto>(
      `${process.env.REACT_APP_DATAQUALITY_API}/api/v1/check-series/${id}`,
      getAuthenticationHeader(),
      {},
      {
        successMessage: "Check serie updated successfully",
        friendlyErrorMessage: "Error retrieving data",
        badRequestErrorMessage: "Bad request",
        notAuthorizedErrorMessage: "Not authorized",
        forbiddenErrorMessage: "Forbidden access",
        notFoundErrorMessage: "Check serie not found"
      } as ToastMessage
    ),
};
