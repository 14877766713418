import * as React from "react";
import { Modal } from "../../common/Modal/Modal";
import { CheckSerieEditDto, CheckSeriesStatusDto } from "../AlertsList/entities.typings";
import { Label } from "../../common/ReadOnlyFields/Label";
import { formatDate, formatStatus } from "../../../common/Helpers/helpers";
import { Select } from "@sgbs-ui/core";
import { useGetCheckSerieById } from "../../../queries/checkSeries";

export interface Props {
  show: boolean;
  onClose: () => void;
  onSubmit: (alertId: number, alertDto: CheckSerieEditDto) => void;
  alertId: number;
  statusesList: CheckSeriesStatusDto[];
}

export const EMPTY_STATE: CheckSerieEditDto = {
  status: "",
  comment: "",
};

export const AlertEditModal: React.FC<Props> = ({ show, onClose, onSubmit, alertId, statusesList }: Props) => {
  const [alertEditModel, setAlertEditModel] = React.useState<CheckSerieEditDto>(EMPTY_STATE);
  const [isAlertModified, setIsAlertModified] = React.useState<boolean>(false);
  const { data: alert } = useGetCheckSerieById(alertId, !!alertId);

  const handleCloseModal = () => {
    if (alert) {
      setAlertEditModel({
        status: formatStatus(alert.checkSeriesStatus),
        comment: alert.comment,
      });
    }
    setIsAlertModified(false);
    onClose();
  };

  const handleOnSubmit = () => {
    if (alert) {
      onSubmit(alert.checkSeriesId, alertEditModel);
    }
    setIsAlertModified(false);
  };

  React.useEffect(() => {
    if (alert) {
      setAlertEditModel({
        status: formatStatus(alert.checkSeriesStatus),
        comment: alert.comment ?? "",
      });
    }
    setIsAlertModified(false);
  }, [alert]);

  return (
    <>
      {alert && (
        <Modal
          size="xs"
          title="Edit alert"
          cancelLabel="Cancel"
          submitLabel="Confirm"
          show={show}
          backdropClose={true}
          onClose={handleCloseModal}
          onCancel={handleCloseModal}
          onSubmit={handleOnSubmit}
          isSubmitValid={isAlertModified}
        >
          <div className="row">
            <div className="col-md-3 form-group">
              <Label displayLabel="Alert id" htmlFor="inpuState " required={false} />
              <input className="form-control" disabled={true} value={alert.checkSeriesId} />
            </div>
            <div className="col-md form-group">
              <Label displayLabel="Date" htmlFor="inpuState " required={false} />
              <input
                className="form-control"
                disabled={true}
                value={alert ? formatDate(alert.receptionDate.toString()) : ""}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md form-group">
              <Label displayLabel="Title" htmlFor="inpuState " required={false} />
              <input className="form-control" disabled={true} value={alert.alertSummary} />
            </div>
          </div>
          <div className="row">
            <div className="col-md form-group">
              <Label displayLabel="Status" htmlFor="inpuState " required={false} />

              <Select.SingleSelect<CheckSeriesStatusDto>
                idField="id"
                labelField="label"
                items={statusesList}
                showClearButton={false}
                selectedItem={{ id: alertEditModel.status, label: alertEditModel.status }}
                onChange={(e: any) => {
                  if (e != null) {
                    setAlertEditModel({
                      ...alertEditModel,
                      status: (e as CheckSeriesStatusDto).label,
                    });
                    setIsAlertModified(true);
                  }
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className=" col-md form-group">
              <Label displayLabel="Comment" htmlFor="inpuState " required={false} />
              <div>
                <textarea
                  className="form-control"
                  value={alertEditModel.comment}
                  onChange={(e) => {
                    setAlertEditModel({
                      ...alertEditModel,
                      comment: e.target.value,
                    });
                    setIsAlertModified(true);
                  }}
                  rows={4}
                  cols={35}
                  maxLength={500}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
