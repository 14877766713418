import { Components } from "@sg-bootstrap/components";
import React, { useEffect, useRef } from "react";
import { dateTodayTimestamp } from "../../../utils/dates/dates";

interface SingleDatePickerProps {
  className?: string;
  value: string | undefined;
  onChange: (date: string | undefined) => void;
  required?: boolean;
  additionalProps?: any;
  disabled?: boolean;
}

export const removeTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  const timezoneOffset = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() - timezoneOffset);

  return date.toISOString().substring(0, 10);
};

const SingleDatePicker: React.FC<SingleDatePickerProps> = ({ value, className, disabled, onChange }) => {
  const ref = useRef<HTMLElement & Components.SgDatepicker>();

  const handleEvent = React.useCallback(
    (event: any) => {
      let newDate = undefined;

      if (event.target.value && !Array.isArray(event.target.value)) {
        newDate = removeTimestamp(event.target.value);
      }

      onChange(newDate);
    },
    [onChange]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let dates: number[] = [];
  const datePicker = ref.current;
  useEffect(() => {
    if (!datePicker || datePicker === undefined) {
      return;
    }

    const timer = setTimeout(() => {
      if (value) {
        dates.push(Date.parse(value));
      } else {
        dates.push(dateTodayTimestamp());
      }
      datePicker?.selectDate?.(dates);
    }, 500);

    datePicker?.addEventListener("dateSelected", handleEvent);
    datePicker?.addEventListener("dateUnSelected", handleEvent);
    datePicker?.addEventListener("inputCleared", handleEvent);

    return () => {
      datePicker?.removeEventListener("dateSelected", handleEvent);
      datePicker?.removeEventListener("dateUnSelected", handleEvent);
      datePicker?.removeEventListener("inputCleared", handleEvent);
      clearTimeout(timer);
    };
  }, [value, dates, datePicker, handleEvent]);

  return (
    <div className={className}>
      <sg-datepicker ref={ref} disabled={disabled} never-empty={true} no-clear-button={true} />
    </div>
  );
};

export default SingleDatePicker;
