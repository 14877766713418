import { IconClose } from "../Icon/Icon";
import { If } from "../If/If";
import { ComponentSize } from "../component.typings";
import { Button } from "@sgbs-ui/core";
import { Loader } from "@sgbs-ui/core";
import React, { useEffect, useRef } from "react";

export interface Props {
  show?: boolean;
  withHeader?: boolean;
  title: string;
  size?: ComponentSize;
  subTitle?: string;
  withAction?: boolean;
  backdropClose?: boolean;
  cancelLabel?: string;
  submitLabel?: string;
  onClose: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  isSubmitValid?: boolean;
  isLoading?: boolean;
  btnSubmitClassName?: string;
  children: JSX.Element[];
}

export const Modal: React.FC<Props> = (props: Props) => {
  const modalContent = useRef<HTMLDivElement>(null);

  const onShouldClose = (event: any) => {
    if (modalContent && !modalContent.current?.contains(event.target) && props.backdropClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    props.show ? document.body.setAttribute("class", "modal-open") : document.body.setAttribute("class", "");
    return function cleanup() {
      document.body.setAttribute("class", "");
    };
  }, [props.show]);

  const {
    show = false,
    withHeader = true,
    withAction = true,
    size = "lg",
    title,
    subTitle,
    cancelLabel,
    onCancel,
    submitLabel,
    onSubmit,
    onClose,
    isSubmitValid = true,
    isLoading,
    btnSubmitClassName = "btn btn-primary",
    children,
  } = props;
  return show ? (
    <>
      <div className="modal fade show d-block" tabIndex={-1} role="dialog" onClick={onShouldClose} aria-hidden="true">
        <div className={`modal-dialog modal-${size}`} role="document">
          <div className="modal-content shadow-max" ref={modalContent}>
            <If condition={withHeader} elseComponent={<span className="pt-5" />}>
              <div className="modal-header">
                <span>
                  <h3 className="modal-title">{title}</h3>
                  {subTitle && <h5 className="modal-title">{subTitle}</h5>}
                </span>
                <button type="button" className="close icon" data-dismiss="modal" onClick={onClose}>
                  <IconClose />
                </button>
              </div>
            </If>
            <div className="modal-body">{children}</div>
            <If condition={withAction}>
              <div className="modal-footer pt-0">
                <Button text={cancelLabel} className="btn btn-outline-secondary" onClick={onCancel} />
                <If condition={!!onSubmit}>
                  {isLoading ? (
                    <Loader isVisible={isLoading} />
                  ) : (
                    <Button
                      onClick={onSubmit}
                      className={btnSubmitClassName}
                      text={submitLabel}
                      disabled={!isSubmitValid}
                    />
                  )}
                </If>
              </div>
            </If>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  ) : null;
};
