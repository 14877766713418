import { get, isEmpty } from "lodash";
import { DateTime } from "luxon";
import * as React from "react";
import { Label } from "../../common/ReadOnlyFields/Label";
import { SearchFiltersState } from "./SearchFilters.typings";
import { Button } from "@sgbs-ui/core";
import { InvalidFeedback } from "../../common/ValidationMessage/InvalidFeedback";
import SingleDatePicker from "../../common/DatePicker/SingleDatePicker";
import MultiSelectPicker from "../../common/MultiSelector/MultiSelectPicker";
import { isValidFormState } from "./SearchFiltersHelper";
import { Referential } from "../../../common/common.typings";
import { useGetCheckSeriesStatuses } from "../../../queries/checkSeries";
import { useGetChecksTypes } from "../../../queries/checks";
import { CheckSeriesStatusDto } from "../AlertsList/entities.typings";

export interface SearchFiltersProps {
  onSearchClick: (searchFilters: SearchFiltersState) => void;
  onStatusesLoaded: (loadedStatuses: CheckSeriesStatusDto[]) => void;
  onSearchFiltersChanged: (searchFilters: SearchFiltersState) => void;
}

export const INIT_STATE: SearchFiltersState = {
  startDate: { isValid: true, date: DateTime.fromJSDate(new Date()).minus({ day: 1 }).toFormat("yyyy-MM-dd") },
  endDate: { isValid: true, date: DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd") },
  alertStatusIds: [],
  alertTypeIds: [],
};

export const SearchFilters: React.FC<SearchFiltersProps> = ({
  onSearchClick,
  onStatusesLoaded,
  onSearchFiltersChanged,
}: SearchFiltersProps) => {
  const [searchFiltersState, setSearchFiltersState] = React.useState<SearchFiltersState>(INIT_STATE);
  const [isFilterStateValid, setIsFilterStateValid] = React.useState<boolean>(false);

  const { data: statuses, refetch: refetchStatuses } = useGetCheckSeriesStatuses(false);
  const checkSeriesStatuses = (get(statuses, "values") ?? []).map((status) => {
    return { id: status.id, label: status.label };
  });
  
  const { data: types, refetch: refetchTypes } = useGetChecksTypes(false) ?? [];
  const checksTypes = (types ?? []).map((type) => {
    return { id: type.id.toString(), label: type.label };
  });

  React.useEffect(() => {
    if (statuses) {
      onStatusesLoaded(statuses?.values);
    }
  }, [onStatusesLoaded, statuses]);

  React.useEffect(() => {
    setIsFilterStateValid(isValidFormState(searchFiltersState));
  }, [searchFiltersState]);

  React.useEffect(() => {
    refetchStatuses();
    refetchTypes();
    onSearchClick(searchFiltersState);
  }, [onSearchClick, refetchStatuses, refetchTypes, searchFiltersState]);

  React.useEffect(() => {
    onSearchFiltersChanged(searchFiltersState);
  }, [onSearchFiltersChanged, searchFiltersState]);

  const onFromDateChange = (startDate: string | undefined) => {
    if (startDate && searchFiltersState.endDate.date) {
      if (DateTime.fromISO(startDate) > DateTime.fromISO(searchFiltersState.endDate.date)) {
        setSearchFiltersState({
          ...searchFiltersState,
          startDate: {
            date: startDate,
            isValid: !isEmpty(startDate),
          },
          endDate: {
            ...searchFiltersState.endDate,
            isValid: false,
            errorMessage: "End date should be greater than start date",
          },
        });
      } else {
        setSearchFiltersState({
          ...searchFiltersState,
          startDate: {
            date: startDate.toString(),
            isValid: !isEmpty(startDate),
          },
        });
      }
    }
  };

  const onToDateChange = (endDate: string | undefined) => {
    if (endDate && searchFiltersState.startDate.date) {
      if (DateTime.fromISO(endDate) < DateTime.fromISO(searchFiltersState.startDate.date)) {
        setSearchFiltersState({
          ...searchFiltersState,
          endDate: {
            date: endDate,
            isValid: false,
            errorMessage: "End date should be greater than start date",
          },
        });
      } else {
        setSearchFiltersState({
          ...searchFiltersState,
          endDate: { date: endDate, isValid: !isEmpty(endDate) },
        });
      }
    }
  };

  const resetForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setSearchFiltersState(INIT_STATE);
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSearchClick(searchFiltersState);
  };

  const handleAlertStatusChange = (items: Referential[]): void => {
    setSearchFiltersState({ ...searchFiltersState, alertStatusIds: items });
  };
  const handleAlertTypeChange = (items: Referential[]): void => {
    setSearchFiltersState({ ...searchFiltersState, alertTypeIds: items });
  };

  return (
    <>
      <form onSubmit={handleSubmit} id="filtersForm">
        <div className="row">
          <div className="col-md-2">
            <Label displayLabel={"From"} htmlFor={"From"} className="text-secondary mb-0" />
            <SingleDatePicker
              className="input-group"
              value={searchFiltersState.startDate.date}
              onChange={(value) => onFromDateChange(value)}
            />
          </div>
          <div className="col-md-2">
            <Label displayLabel={"To"} htmlFor={"To"} className="text-secondary mb-0" />
            <SingleDatePicker
              className="input-group"
              value={searchFiltersState.endDate.date}
              onChange={(value) => onToDateChange(value)}
            />
            {!searchFiltersState.endDate.isValid && (
              <InvalidFeedback errorMessage={searchFiltersState.endDate.errorMessage} errorType={"danger"} />
            )}
          </div>
          <div className="col-md-3">
            <Label displayLabel={"Alert Status"} htmlFor={"Alert Status"} className="text-secondary mb-0" />
            <MultiSelectPicker
              onChange={handleAlertStatusChange}
              selectedIds={searchFiltersState.alertStatusIds}
              itemsProps={checkSeriesStatuses}
            />
          </div>
          <div className="col-md-3">
            <Label displayLabel={"Alert Type"} htmlFor={"Alert Type"} className="text-secondary mb-0" />
            <MultiSelectPicker
              onChange={handleAlertTypeChange}
              selectedIds={searchFiltersState.alertTypeIds}
              itemsProps={checksTypes}
            />
          </div>
          <div className="col-md-2 mt-3">
            <Button text="Reset" className="btn-lg btn-outline-secondary mb-2 mr-0" onClick={resetForm} />
            <Button
              type="submit"
              className="btn-lg btn-primary ml-2 mb-2 mr-0"
              text="Search"
              disabled={!isFilterStateValid}
            />
          </div>
        </div>
      </form>
    </>
  );
};
