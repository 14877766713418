import React from "react";
import { FormattedMessage } from "react-intl";
import AlertListContainer from "../../Alerts/AlertListContainer";

export const Home: React.FC = () => {
  return (
    <div className="main-content">
      <div className="row pl-2">
        <div className="col-12 col-sm-8 p-4">
          <h3 className="display-3">
            <FormattedMessage id="home.welcome" />
          </h3>
        </div>
      </div>
      <div className="col-12">
        <AlertListContainer />
      </div>
    </div>
  );
};
