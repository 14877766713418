import * as React from "react";

export interface Props {
  id?: string;
  iconName: string;
  className: string;
  placeHoldertext: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

export const SearchInput: React.FC<Props> = (props: Props) => {
  const { id, iconName, className, onChange, value, placeHoldertext } = props;

  return (
    <div className="input-group">
      <div className="input-group-prepend input-group-merged">
        <i className="icon icon-sm text-secondary">{iconName}</i>
      </div>
      <input
        type="text"
        id={id ?? "filter-text-box"}
        onChange={onChange}
        className={className}
        value={value}
        placeholder={placeHoldertext}
      />
    </div>
  );
};
