import { ChecksTypesDto } from "../components/Alerts/AlertsList/entities.typings";
import { createRepository, getAuthenticationHeader } from "./api";
import { IChecksApi } from "./checks.api.typing";

export const ChecksApi: IChecksApi = {
  getChecksTypes: (): Promise<ChecksTypesDto[]> =>
    createRepository().get<ChecksTypesDto[]>(
      `${process.env.REACT_APP_DATAQUALITY_API}/api/v1/checks/types`,
      getAuthenticationHeader()
    ),
};
