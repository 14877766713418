import { useState, useEffect  } from "react";
import { getUserEmail } from "../../helpers";
import { useGetUserProfile } from "../../queries/users";
import { PageForbidden } from "./PageForbidden";
import { PageNotAuthorized } from "./PageNotAuthorized";
import { UserClaim } from "../../common/common.typings";

interface AuthenticationProps {
  children: React.ReactNode;
}

export const Authentication: React.FC<AuthenticationProps> = ({ children }: AuthenticationProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userClaims, setUserClaims] = useState<UserClaim[]>([]);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const { data: cerberusResponse, status, error } = useGetUserProfile(getUserEmail() ?? "", isLoading);

  useEffect(() => {
    switch(status) {
      case "error":
        setIsLoading(false);
        setIsAuthorized((error as Response)?.status !== 401);
        break;
      case "success":
        setIsLoading(false);
        setIsAuthorized(true);
        setUserClaims(cerberusResponse?.validatedRequestCProfit?.claims ?? []);
        break;
      default:
        setIsLoading(true);
    }
  }, [cerberusResponse?.validatedRequestCProfit?.claims, error, status]);

  const doesUserHaveClaims = (): boolean => {
    return userClaims.find(x => x.domainId === "dataquality" && x.claimId === "profile") !== undefined;
  }

  return (
    <>
      {
        isLoading ? 
          <div className="spinner-grow spinner-grow-sm" role="status">
            Loading...
          </div>
        : (isAuthorized) ?
          (doesUserHaveClaims()) ?
            children
            : <PageForbidden />
          : <PageNotAuthorized />
      }
    </>
  );
};
