import { map } from "lodash";
import { CheckSerieEditDto, CheckSeriesFilterDto } from "../components/Alerts/AlertsList/entities.typings";

export const buildFilters = (filters: CheckSeriesFilterDto): string => {
  let convertedFilter: string = `from=${filters.From}&to=${filters.To}`;
  map(filters.CheckSeriesStatus, (x) => {
    convertedFilter = convertedFilter.concat(`&checkSeriesStatuses=${x}`);
  });
  map(filters.CheckTypeId, (x) => {
    convertedFilter = convertedFilter.concat(`&checkTypeIds=${x}`);
  });
  return convertedFilter;
};

export const getEditCheckSerieQueryParams = (editCheckSerieDto: CheckSerieEditDto): string => {
  let queryParams: string = "";
  Object.keys(editCheckSerieDto).forEach((key: string) => {
    queryParams += `${key}=${editCheckSerieDto[key as keyof CheckSerieEditDto]}&`;
  });
  return queryParams.substring(0, queryParams.length - 1);
};
