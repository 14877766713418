import { Loader } from "@sgbs-ui/core";
import { get } from "lodash";
import * as React from "react";
import { useEditCheckSerie, useGetAlertsByFilters } from "../../queries/checkSeries";
import { SearchFilters } from "./SearchFilters/SearchFilters";
import { SearchFiltersState } from "./SearchFilters/SearchFilters.typings";
import AlertsList from "./AlertsList/AlertsList";
import { CheckSerieEditDto, CheckSeriesItemDto, CheckSeriesStatusDto } from "./AlertsList/entities.typings";
import { stateToDto } from "./AlertListContainer.helper";
import { AlertEditModal } from "./AlertEdition/AlertEditModal";
import { AlertDetailsModal } from "./AlertDetails/AlertDetailsModal";

const AlertListContainer: React.FC = () => {
  const [alerts, setAlerts] = React.useState<CheckSeriesItemDto[]>([]);
  const [searchFiltersState, setSearchFiltersState] = React.useState<SearchFiltersState | null>(null);
  const [currentEditAlertId, setCurrentEditAlertId] = React.useState<number>();
  const [checkSeriesStatuses, setCheckSeriesStatuses] = React.useState<CheckSeriesStatusDto[]>([]);
  const [searchFiltersStateCache, setSearchFiltersStateCache] = React.useState<SearchFiltersState | null>(null);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [currentDetailId, setCurrentDetailId] = React.useState<number>();

  const { isLoading, data, refetch } = useGetAlertsByFilters(stateToDto(searchFiltersState), !!searchFiltersState);
  const { mutate } = useEditCheckSerie();

  React.useEffect(() => {
    setAlerts(get(data, "checkSeriesItemsDto") ?? []);
  }, [data]);

  const onCloseModalEditAlert = () => {
    setShowEditModal(false);
  };

  const onSubmitModalEditAlert = async (id: number, editAlertDto: CheckSerieEditDto) => {
    mutate(
      {
        id: id,
        checkSerieDto: editAlertDto,
      },
      {
        onSuccess: () => {
          setSearchFiltersState(searchFiltersStateCache);
          setShowEditModal(false);
          refetch();
        },
      }
    );
  };

  const onEditAlert = async (e: React.MouseEvent<HTMLButtonElement>, alertId: number) => {
    e.preventDefault();
    setCurrentEditAlertId(alertId);
    setShowEditModal(true);
  };

  const onOpenDetailsAlert = async (e: React.MouseEvent<HTMLButtonElement>, alertId: number) => {
    e.preventDefault();
    setCurrentDetailId(alertId);
    setShowDetailsModal(true);
  };

  const onCloseModalAlertDetails = () => {
    setShowDetailsModal(false);
  };

  return (
    <div>
      <SearchFilters
        onSearchClick={setSearchFiltersState}
        onStatusesLoaded={setCheckSeriesStatuses}
        onSearchFiltersChanged={setSearchFiltersStateCache}
      />
      <hr />
      <Loader isVisible={isLoading} />
      <AlertsList alerts={alerts} onEditAlert={onEditAlert} onOpenDetailsAlert={onOpenDetailsAlert} />
      {currentEditAlertId && (
        <AlertEditModal
          show={showEditModal}
          onClose={onCloseModalEditAlert}
          onSubmit={onSubmitModalEditAlert}
          alertId={currentEditAlertId}
          statusesList={checkSeriesStatuses}
        />
      )}
      {currentDetailId && (
        <AlertDetailsModal show={showDetailsModal} onClose={onCloseModalAlertDetails} checkSerieId={currentDetailId} />
      )}
    </div>
  );
};

export default AlertListContainer;
