import { CheckAlertsDto } from "../components/Alerts/AlertsList/entities.typings";
import { createRepository, getAuthenticationHeader } from "./api";
import { ICheckAlertsApi } from "./checkAlerts.api.typing";

export const CheckAlertsApi: ICheckAlertsApi = {
    getCheckAlertsById: (id: number): Promise<CheckAlertsDto> =>
    createRepository().get<CheckAlertsDto>(
      `${process.env.REACT_APP_DATAQUALITY_API}/api/v1/check-alerts?id=${id}`,
      getAuthenticationHeader()
    ),
};
