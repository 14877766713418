import { ChildrenRenderProps, Select, SGBSSize } from "@sgbs-ui/core";
import { isEmpty } from "lodash";
import * as React from "react";
import { Referential } from "../../../common/common.typings";
import { InvalidFeedback } from "../ValidationMessage/InvalidFeedback";

export interface Props {
  placeholder?: string;
  disabled?: boolean;
  selectedIds: Referential[];
  withFunds?: boolean;
  size?: SGBSSize;
  outline?: boolean;
  onChange: (status: Referential[]) => void;
  itemsProps: Referential[];
  inError?: boolean;
  errorMessage?: string;
}

const MultiSelectPicker: React.FC<Props> = ({
  errorMessage,
  inError,
  selectedIds,
  placeholder,
  onChange,
  disabled,
  size,
  outline,
  itemsProps,
}: Props) => {
  const [filteredItems, setFilteredItems] = React.useState<Referential[]>([]);

  React.useEffect(() => {
    setFilteredItems(itemsProps);
  }, [itemsProps, selectedIds]);

  const handleOnSelect = (selected: Referential[]): void => {
    if (selected) {
      onChange(selected);
    }
  };

  const onTermsChange = (terms: string): void => {
    if (!isEmpty(terms)) {
      const items = itemsProps?.filter(({ label }) => label.toLocaleLowerCase().includes(terms.toLocaleLowerCase()));
      setFilteredItems(items);
    } else if (isEmpty(terms)) {
      setFilteredItems(itemsProps);
    }
  };

  const renderItems = ({ label }: Referential, { withHighlight }: ChildrenRenderProps): React.ReactElement => {
    return <div>{withHighlight(label ?? "")}</div>;
  };

  const error = errorMessage ?? "";

  return (
    <>
      <Select.AsyncSelect<Referential>
        items={filteredItems}
        selectedItems={selectedIds}
        isLoading={false}
        idField="id"
        disabled={disabled}
        labelField="label"
        onTermChange={onTermsChange}
        onChange={handleOnSelect}
        size={size}
        iconName="search"
        placeholder={placeholder}
        keepOrder={false}
        isOutline={outline}
        inError={inError}
        errorMessage={error}
        noResultMessage={"No results found."}
      >
        {renderItems}
      </Select.AsyncSelect>
      {error && <InvalidFeedback errorMessage={error} />}
    </>
  );
};

export default MultiSelectPicker;
